//ro罗马尼亚
export const ro = {
    //Bară de navigare
    'n1': 'Acasă',
    'n2': 'Comanda',
    "n3": "Tranzacție",
    "n4": "Echipă",
    'n5': 'al meu',
    //clasa prompta
    't1': 'A apărut o eroare',
    "t2": "Copiere reușită",
    "t3": "Vă rugăm să completați complet informațiile",
    't4': 'Se încarcă...',
    't5': 'Conectare reușită',
    "t6": "Scorajul trebuie să fie mai mare de 3 pentru a trimite",
    "t7": "Trimitere reușită",
    "t8": "Sold insuficient, imposibil de primit",
    "t9": "Trimiterea a eșuat",
    "t10": "Vă rugăm să legați mai întâi adresa și veți fi redirecționat către pagina centrului personal",
    "t11": "Adresa a fost legată, vă rugăm să contactați serviciul pentru clienți pentru ao modifica",
    't12': 'Modificare reușită',
    "t13": "Modificarea eșuată",
    "t14": "Vă rugăm să contactați serviciul clienți pentru a vă schimba parola",
    "t15": "Eroare de format de parolă",
    "t16": "Retragere reușită",
    "t17": "Retrageri maxime",
    "t18": "Retragere minimă",
    "t19": "Două parole sunt inconsistente",
    "t20": "Prompt",
    "t21": "Nu se poate sări temporar",
    "t22": "Eroare de format de parolă",
    't23': 'Înregistrare reușită',
    "t24": "Vă rugăm să introduceți codul zonei",
    "t25": "Nu mai mult",
    //Inscrie-te
    'l1': 'Limba',
    "l2": "Vă rugăm să introduceți numărul de cont",
    'l3': 'Vă rugăm să introduceți parola',
    'l4': 'ţine minte',
    'l5': 'Autentificare',
    "l6": "Mergeți la înregistrare",
    'l7': 'Fără cont',
    "l8": "Număr de telefon mobil",
    'l9': 'porecla',
    "l10": "parolă",
    "l11": "Confirmați parola",
    "l12": "cod de invitație",
    'l13': 'Înregistrare',
    "l14": "Aveți deja un cont",
    "l15": "Accesați la autentificare",
    "l16": "Vă rugăm să introduceți o parolă de 6-16 cifre",

    //Ajutor
    'bz1': 'Ajutor',
    "bz2": "Întrebări frecvente",
    "bz3": "Reguli de tranzacționare",
    "bz4": "Cum să retragi bani",
    "bz5": "Cum se reîncarcă",
    'bz6': '1.Ce este TiktokShop?',
    "bz7": "TiktokShop este o companie de marketing și promovare care îi ajută pe comercianții magazinelor online TiktokShop din întreaga lume să obțină mai multe vânzări de comenzi și să mărească datele de navigare ale magazinelor online TiktokShop. Ne angajăm să stabilim un model tripartit de marketing și promovare profitabil între TiktokShop, comercianți și consumatori. În combinație cu cea mai recentă tehnologie blockchain P2P, consumatorii și comercianții sunt conectați rapid prin USDT (TRC20, ERC20). Utilizatorii înregistrați pot obține comisioane la comenzi, în timp ce comercianții pot crește datele de vânzări ale magazinului lor. Este cel mai recent model de profit din modelul de Internet blockchain! ",
    'bz8': '2.Cum funcționează TiktokShop?',
    "bz9": "Feedback-ul zilnic de la TiktokShop arată că produsele care necesită îmbunătățiri sunt vândute în TiktokShop. Utilizatorii trebuie doar să facă clic cu ușurință pe comandă, iar sistemul generează automat un abonament de comandă. Utilizatorii plătesc suma comenzii prin blockchain USDT și primesc comisioane zilnice. ",
    "bz10": "3. De ce sunt diferite prețurile aceleiași monede în două tranzacții? ",
    "bz11": "Diferențe de preț sunt cauzate de toți factorii care nu sunt propice pentru libera circulație a valutelor, inclusiv viteza transferurilor valutare, condițiile rețelei, restricțiile de acces la valută, recunoașterea monedei de către oameni din diferite regiuni și chiar perechile de tranzacționare. furnizate de tipuri de schimburi, tranzacții etc. Prin urmare, aceeași monedă poate avea diferențe de preț în diferite tranzacții. ",
    "bz12": "4. Profit de investiție? ",
    "bz13": "Cu cât prețul produsului este mai mare, cu atât este mai mare profitul din comandă. În același timp, TiktokShop distribuie aleatoriu comenzi mari cu comisioane. ",
    "bz14": "În fiecare zi, sistemul generează și distribuie automat 60 de comenzi de produse către utilizatori. Utilizatorii finalizează plata pentru fiecare comandă prin USDT și primesc un comision de 0.6%. Comenzile mari cu comisioane sunt repartizate aleatoriu. ",
    "bz15": "USDT poate fi retras după 60 de comenzi. (Dacă 60 de comenzi nu sunt finalizate, sistemul se va opri automat până la comenzile rămase ale zilei)",
    "bz16": "Fiecare cont poate fi legat doar la un cont USDT. Dacă apare o eroare, contactați serviciul pentru clienți",
    "bz17": "După finalizarea livrării comenzilor zilnice, puteți retrage bani în mod normal. Va ajunge în 24 de ore de la începerea retragerii și nu există nicio limită de retragere",
    "bz18": "Vă rugăm să vă legați adresa de retragere (acceptă TRC-20) adresa USDT înainte de reîncărcare. Puteți da clic pe Reîncărcare în APP sau pe pagina web și selectați blockchain-ul (TRC-20) pe care îl utilizați pentru reîncărcare. ",
    "bz19": "Reîncărcarea sau transferul de fonduri între conturile aferente nu este permisă.Acesta este un comportament rău intenționat de preluare a comenzilor și afectează grav regulile de funcționare ale platformei.Dacă se găsește un astfel de comportament, sistemul va amenda contul.Prima penalizare va fi 15 % din fondurile din cont",
    "bz20": "Conturile asociate trebuie să finalizeze sarcinile de comandă din contul fiecărei zile înainte de a putea continua să actualizeze sarcinile de comandă pentru ziua următoare.Dacă există o sarcină neterminată între conturile asociate, actualizarea sarcinilor de comandă se va opri până când toate sarcinile de comandă sunt finalizate",
    //textul modulului
    //Cuvinte uzuale
    'ty1': 'OK',
    "ty2": "Anulează",
    'ty3': 'Trimite',
    "ty4": "adresă",
    //prima pagina
    "s1": "Venit cumulat",
    's2': 'Serviciu',
    's3': 'Despre noi',
    's4': 'Ajutor',
    's5': 'Partener',
    's6': 'Serviciul meu',
    //Ordin
    "d1": "Neterminat",
    "d2": "Finalizat",
    "d3": "Nu există date încă",
    "d4": "număr impar",
    "d5": "Timp de tranzacționare",
    "d6": "suma",
    "d7": "Numărul curent de sarcini",
    "d8": "Revenire așteptată",
    "d9": "Am nevoie de mai mult",
    "d10": "Evaluare",
    //comerț
    "j1": "Soldul contului",
    "j2": "Introducere comercială",
    "j3": "TiktokShop folosește feedback-ul TiktokShop în fiecare zi pentru a arăta produsele vândute de TiktokShop care necesită îmbunătățiri. Utilizatorii trebuie doar să facă clic pe comandă, iar sistemul va genera automat o semnătură de comandă. Utilizatorii plătesc suma comenzii prin blockchain-ul USDT și pot primi mai mult de 0,6% comision de fiecare dată, iar sistemul distribuie aleatoriu comenzi mari de recompensă. ",
    "j4": "Începeți potrivirea",
    "j5": "Câștiguri de astăzi",
    "j6": "Număr completat",
    "j7": "Numărul total de sarcini",
    "j8": "Dacă doriți să reîncărcați",
    "j9": "Echilibru insuficient",
    //echipă
    "td1": "Sold",
    'td2': 'comision',
    'td3': 'Primire',
    "td4": "Numărul total de persoane",
    'td5': 'utilizator',
    'td6': 'contribuție',
    'td7': 'Cantitate',
    'td8': 'stare',
    //Centrul personal
    'g1': 'Retragere',
    "g2": "Reîncărcare",
    "g3": "Invitați prieteni",
    "g4": "Invitați acum",
    "g5": "Pentru fiecare profit realizat de prietenul tău după înregistrare, vei primi o proporție corespunzătoare din comision",
    "g6": "Informații personale",
    "g7": "Detalii fond",
    "g8": "Înregistrare reîncărcare",
    "g9": "Înregistrare de retragere",
    "g10": "Limba",
    "g11": "Deconectați-vă",
    'g12': 'Sunteți sigur că doriți să vă deconectați?',
    //Informații despre utilizator
    "yh1": "Informații despre utilizator",
    'yh2': 'Modifică',
    'yh3': 'setare',
    "yh4": "telefon",
    'yh5': 'Sunteți sigur că doriți să vă deconectați?',
    'yh6': 'Parola originală',
    'yh7': 'Parolă nouă',
    //Detalii
    'mx1': 'Colectare comision',
    'mx2': 'profit',
    "mx3": "Retragere respinsă",
    'mx4': 'timp',
    'mx5': 'Reîncărcare cu succes',
    "mx6": "Respins",
    "mx7": "în așteptare",
    'mx8': 'Adresă de retragere',
    "mx9": "Sold disponibil",
    "mx10": "Sosire reală",
    "mx11": "Vă rugăm să introduceți suma de retragere",
    //umple
    "cz1": "Adresa de depunere acceptă doar ERC20, iar suma minimă de depunere este 20USDT",
    'cz2': 'Copiere',
    "cz3": "Selectarea monedei Fiat",
    "cz4": "plată",
    "cz5": "Adresa de depunere acceptă doar TRC20-USDT, iar suma minimă de depunere este de 20USDT",
    "cz6": "Adresa de depunere acceptă doar ERC20, iar suma minimă de depunere este 20USDT",
    "cz1": "Adresa de depunere acceptă doar ERC20, iar suma minimă de depunere este 20USDT",
    "cz1": "Adresa de depunere acceptă doar ERC20, iar suma minimă de depunere este 20USDT",
    //zonă
    "qy1": "cod zonă",
    'yq1': 'Invitați prieteni',
    'yq2': 'cod de invitație',
    'yq3': 'Invită prieteni și bucură - te de avantaje mari',
    'yq4': 'Du - te și invită',
}
